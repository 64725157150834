<template>
	<div class="">
		<b-row v-if="!showSubmations">
			<b-col v-for="report in reports" :key="report.name" clos="12" sm="6" md="4">
				<report-card :data="report" :loading="loading" @viewsubmations="setCurrentReport(report)" />
			</b-col>
		</b-row>
		<div v-else class="">
			<b-tabs pills lazy>
				<b-tab v-for="report in reports" :key="`${report.name}-details`" :active="currentReport.name === report.name">
					<template #title>
						<span class="font-weight-bold">{{ report.name }}</span>
					</template>

					<base-table :headers="report.headers" :items="report.submations" paginate table-top>
						<template #cell(images)="{ item }">
							<table-images :images="item.images"></table-images>
						</template>

						<template #cell(map)="{ item }">
							<table-map :loc="item.location"></table-map>
						</template>
					</base-table>
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>

<script>
/* eslint-disable no-param-reassign */

import store from '@/store';
import { BRow, BCol, BTabs, BTab, VBTooltip } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import TableImages from '@/components/TableImages.vue';
import TableMap from '@/components/TableMap.vue';
import ReportCard from './report-card.vue';

export default {
	name: 'ProjectReportsData',

	components: {
		BRow,
		BCol,
		BTabs,
		BTab,

		ReportCard,
		BaseTable,
		TableImages,
		TableMap
	},

	directives: {
		'b-tooltip': VBTooltip
	},

	data: () => ({
		showSubmations: false,
		loading: false,
		currentReport: undefined,

		reports: [
			{
				name: 'تقارير المعدات والآلات',
				img: 'badge.svg',
				submations: [],
				headers: [
					{ key: 'date' },
					{ key: 'machine' },
					{ key: 'value' },
					{ key: 'note', label: 'notes' },
					{ key: 'images' },
					{ key: 'map' }
				]
			},
			{
				name: 'تقارير المواد',
				img: 'badge.svg',
				submations: [],
				headers: [
					{ key: 'date' },
					{ key: 'material' },
					{ key: 'value' },
					{ key: 'note', label: 'notes' },
					{ key: 'images' },
					{ key: 'map' }
				]
			},
			{
				name: 'تقارير الزيارات',
				img: 'badge.svg',
				submations: [],
				headers: [{ key: 'date' }, { key: 'visitor' }, { key: 'note', label: 'Notes' }, { key: 'images' }, { key: 'map' }]
			}
		]
	}),

	async mounted() {
		this.loading = true;
		try {
			await this.loadVisits();
			await this.loadMachines();
			await this.loadMaterials();
			this.loading = false;
		} catch (error) {
			this.loading = false;
		}
	},

	methods: {
		setCurrentReport(report) {
			this.currentReport = report;
			this.showSubmations = true;
		},

		async loadMaterials() {
			const { data } = await store.dispatch('projects/getMaterialsReports', this.$route.params.id);
			const items = [];
			data.data.forEach(({ answers, location }) => {
				const item = answers.reduce((result, field) => {
					if (field.field_type === 'ListField') {
						result.material = field.answer;
					}

					if (field.field_type === 'DateField') {
						result.date = field.answer;
					}

					if (field.field_type === 'ImageField') {
						result.images = field.media;
					}

					if (field.field_type === 'TextField' && field.field_id === 910) {
						result.value = field.answer;
					}

					if (field.field_type === 'TextField' && field.field_id === 911) {
						result.note = field.answer;
					}

					return result;
				}, {});

				item.location = [location.latitude, location.longitude];
				item.map = '';

				items.push(item);
			});

			this.reports[1].submations = items;
		},

		async loadMachines() {
			const { data } = await store.dispatch('projects/getMachinesReports', this.$route.params.id);
			const items = [];
			data.data.forEach(({ answers, location }) => {
				const item = answers.reduce((result, field) => {
					if (field.field_type === 'ListField') {
						result.machine = field.answer;
					}

					if (field.field_type === 'DateField') {
						result.date = field.answer;
					}

					if (field.field_type === 'ImageField') {
						result.images = field.media;
					}

					if (field.field_type === 'TextField' && field.field_id === 912) {
						result.value = field.answer;
					}

					if (field.field_type === 'TextField' && field.field_id === 913) {
						result.note = field.answer;
					}

					return result;
				}, {});

				item.location = [location.latitude, location.longitude];
				item.map = '';

				items.push(item);
			});

			this.reports[0].submations = items;
		},

		async loadVisits() {
			const { data } = await store.dispatch('projects/getVisitsReports', this.$route.params.id);
			const items = [];
			data.data.forEach(({ answers, location }) => {
				const item = answers.reduce((result, field) => {
					if (field.field_type === 'DateField') {
						result.date = field.answer;
					}

					if (field.field_type === 'ImageField') {
						result.images = field.media;
					}

					if (field.field_type === 'TextField' && field.field_id === 914) {
						result.visitor = field.answer;
					}

					if (field.field_type === 'TextField' && field.field_id === 915) {
						result.note = field.answer;
					}

					return result;
				}, {});

				item.location = [location.latitude, location.longitude];
				item.map = '';

				items.push(item);
			});

			this.reports[2].submations = items;
		}
	}
};
</script>

<style lang="scss" scoped></style>
