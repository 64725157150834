<template>
	<div>
		<div class="app-calendar overflow-hidden border">
			<div class="row no-gutters">
				<!-- Calendar -->
				<div class="col position-relative">
					<div class="card shadow-none border-0 mb-0 rounded-0">
						<div class="card-body pb-0">
							<full-calendar ref="refCalendar" :options="calendarOptions" class="full-calendar" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal v-model="showReports" size="lg" :title="`Reports for ${clickedDate}`" hide-footer centered>
			<div v-if="loading" class="">
				<div class="text-center text-primary mt-3 mb-2">
					<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
					<span class="d-block mt-1">Loading...</span>
				</div>
			</div>
			<base-table v-else :items="reports" :headers="tableHeaders" paginate table-top class="shadow-none border-secondary">
				<template #cell(images)="row">
					<table-images :images="row.item.images"></table-images>
				</template>
				<template #cell(map)="row">
					<table-map :loc="row.item.map"></table-map>
				</template>
				<template #cell(paid_amount)="{ value }">
					<strong>{{ formatMoney(value) }}</strong>
				</template>
			</base-table>
		</b-modal>
	</div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { BModal, BSpinner } from 'bootstrap-vue';
import FullCalendar from '@fullcalendar/vue';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import store from '@/store';
import BaseTable from '@/components/BaseTable.vue';
import TableImages from '@/components/TableImages.vue';
import TableMap from '@/components/TableMap.vue';
import { formatMoney } from '@/utils/helpers';

export default {
	name: 'OverView',

	components: { FullCalendar, BModal, BSpinner, BaseTable, TableImages, TableMap },

	data() {
		return {
			lastDate: '',

			clickedDate: '',

			showReports: false,
			loading: false,

			reports: [],
			reportsType: '',

			calendarOptions: {
				plugins: [dayGridPlugin, interactionPlugin],
				initialView: 'dayGridMonth',
				headerToolbar: {
					start: 'prev, next, title',
					end: ''
				},
				events: [],

				eventClassNames({ event: calendarEvent }) {
					const colorName = calendarEvent._def.extendedProps.variant;

					return [`bg-light-${colorName}`];
				},

				eventClick: async ({ event }) => {
					const dateObj = event._instance.range.start;
					const { type } = event._def.extendedProps;

					this.getReports(dateObj.toISOString().split('T')[0], type);
				},

				datesSet: async (event) => {
					await this?.updateReports(event.start.toISOString().split('T')[0], event.end.toISOString().split('T')[0]);
				}
			}
		};
	},

	computed: {
		tableHeaders() {
			const common = [
				{ key: 'created_by', sortable: false },
				{ key: 'images', sortable: false },
				{ key: 'map', sortable: false }
			];

			if (this.reportsType === 'items') {
				return [{ key: 'item', sortable: false }, { key: 'executed_quantity', sortable: false }, ...common];
			}

			if (this.reportsType === 'paying') {
				return [{ key: 'full_name', sortable: false }, { key: 'paid_amount', sortable: false }, ...common];
			}

			return [{ key: 'full_name', sortable: false }, ...common];
		}
	},

	methods: {
		async updateReports(startDate, endDate) {
			if (this.lastDate === startDate) return;
			this.lastDate = startDate;

			const { data } = await store.dispatch('projects/getReportsOverview', {
				projectId: this.$route.params.id,
				startDate,
				endDate
			});

			const events = [];

			events.push(
				...data.items.map((item) => ({
					title: `(${item.total}) Items reports`,
					date: item.day_date,
					extendedProps: {
						variant: 'info',
						type: 'items'
					}
				}))
			);

			events.push(
				...data.beneficiaries.map((item) => ({
					title: `(${item.total}) Beneficiary reports`,
					date: item.day_date,
					extendedProps: {
						variant: 'success',
						type: 'beneficiary'
					}
				}))
			);

			events.push(
				...data.paying.map((item) => ({
					title: `(${item.total}) Paying reports`,
					date: item.day_date,
					extendedProps: {
						variant: 'warning',
						type: 'paying'
					}
				}))
			);

			this.calendarOptions.events = events;
		},

		async getReports(date, type) {
			this.loading = true;
			this.showReports = true;
			this.clickedDate = date;

			try {
				if (type === 'items') {
					this.reportsType = 'items';
					this.reports = await store.dispatch('items/getReports', {
						id: this.$route.params.id,
						date
					});
				} else if (type === 'paying') {
					this.reportsType = 'paying';
					this.reports = await store.dispatch('workers/getPayingReports', {
						projectId: this.$route.params.id,
						date
					});
				} else {
					this.reportsType = 'workers';
					this.reports = await store.dispatch('workers/getDailyReports', {
						projectId: this.$route.params.id,
						date
					});
				}

				this.loading = false;
			} catch (error) {
				this.loading = false;
				console.log(error);
			}
		},

		formatMoney
	}
};
</script>

<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';
</style>
