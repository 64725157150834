<template>
	<div>
		<b-tabs vertical content-class="col-12 col-md-10 mt-1 mt-md-0" pills nav-wrapper-class="col-md-2 col-12" nav-class="nav-left" lazy>
			<b-tab title="Overview">
				<Overview />
			</b-tab>
			<b-tab title="Data">
				<ReportData />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import Overview from './overview.vue';
import ReportData from './reports-data.vue';

export default {
	name: 'ProjectReportsIndex',

	components: { BTabs, BTab, ReportData, Overview }
};
</script>

<style lang="scss" scoped></style>
