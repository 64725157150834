<template>
	<div class="">
		<b-card v-if="data" class="report-card">
			<h3 class="font-weight-bold">{{ data.name }}</h3>
			<h6 class="mb-75 mt-2 pt-50">
				<span class="d-block mb-50">Number of submissions</span>
				<div v-if="loading" class="">
					<div class="text-primary mt-3 mb-2">
						<b-spinner class="align-middle" style="width: 2rem; height: 2rem;"></b-spinner>
					</div>
				</div>
				<h2 v-else class="font-weight-bolder mb-1">{{ data.submations.length }}</h2>
			</h6>
			<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" :disabled="loading" @click="$emit('viewsubmations')">
				View submissions
			</b-button>
		</b-card>
	</div>
</template>

<script>
import { BCard, BSpinner, BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
	name: 'ReportCard',

	components: {
		BCard,
		BSpinner,
		BButton
	},

	directives: {
		Ripple
	},

	props: {
		data: {
			type: Object,
			default: () => ({
				name: 'Report name gose here!',
				submations: [],
				img: 'badge.svg'
			})
		},

		loading: {
			type: Boolean
		}
	}
};
</script>

<style lang="scss" scoped>
.report-card {
	border-right: 5px solid #8bc53f;
}
</style>
